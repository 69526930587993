@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: 'manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none !important;
}

.Category_menu {
  z-index: 999 !important;
  position: relative;
}

table {
  border-radius: 2rem;
  overflow: hidden;
}
table td {
  padding: 0.8rem;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
  font-size: 0.75rem !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 6px !important;
}

.css-omo7y9-MuiPaper-root-MuiAccordion-root:before {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 0px !important;
  content: '';
  opacity: 0 !important;
}

.cover-letter-paragraph > html > body {
  overflow-y: hidden !important;
}

input[type='date'] {
  display: block;
  min-width: 95%;
  height: 100%;
}

.slick-track {
  display: flex !important;
}

#single__link__child {
  display: none;
  transition: all 0.3s ease-in-out;
}

#single__header__link:hover #single__link__child {
  display: flex;
  flex-direction: column;
}

#homepage__header {
  background-image: url('../assets/img/home-header.jpeg');
  background-position: center;
  background-size: cover;
}

#login_background {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url('../assets/img/login-bg.jpg');
  background-position: center;
  background-size: cover;
}

#drop_shadow_image {
  filter: drop-shadow(4px 11px 16px #b2b2b2);
}

#profile_bg {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.6)
    ),
    url('../assets/img/github.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#resume_bg {
  background-image: url('https://media.istockphoto.com/id/1371944453/vector/blue-abstract-wave-background.jpg?s=612x612&w=0&k=20&c=uaCU_xpsKQLnbnvJH99iAi9uUqEAoS4jcyDwHXlLq5M=');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.95);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }
}

@import 'loader.css';
@import 'draft.css';
@import 'react-quill.css';
